import { FC } from 'react'
import { styled } from '@sans-souci/styles'
import { SectionHalvesSimpleProps } from '@sans-souci/models'
import { HalvesElementAndMedia, Link } from '@sans-souci/components'

const ElementWrap = styled('div', {
  display: 'grid',
  gridAutoRows: 'min-content',

  gap: '$20',
  justifySelf: 'center',
  alignSelf: 'center',

  '@sm': {
    maxWidth: '$maxWidthXS',
  },

  '@lg': {
    gap: '$40',
  },
})

const Title = styled('h2', {
  $projectFont: 'heading02',
  textAlign: 'center',

  '@lg': {
    textAlign: 'left',
  },
})

const Text = styled('p', {
  $projectFont: 'body01',
  textAlign: 'center',

  '@lg': {
    textAlign: 'left',
  },
})

const StyledLink = styled(Link, {
  width: 'fit-content',
  justifySelf: 'center',

  '@lg': {
    justifySelf: 'start',
  },
})

export const SectionHalvesSimple: FC<SectionHalvesSimpleProps> = ({
  title,
  text,
  priority,
  media,
  mediaPosition,
  ctaLink,
  darkTheme,
  _key,
}) => {
  return (
    <HalvesElementAndMedia
      media={media}
      mediaPosition={mediaPosition}
      priority={priority}
      textColor={darkTheme ? 'beige' : 'black'}
      equalRows
      id={_key}
    >
      <ElementWrap>
        {title && <Title>{title}</Title>}
        {text && <Text>{text}</Text>}
        {ctaLink && ctaLink.title && (
          <StyledLink appearance={'ButtonPrimaryWhite'} {...ctaLink.payload}>
            {ctaLink.title}
          </StyledLink>
        )}
      </ElementWrap>
    </HalvesElementAndMedia>
  )
}
